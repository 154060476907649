<template>
  <div class="solve">
    <div class="head-public animate__animated animate__fadeIn">
      <img src="@/assets/img/banner5.png" alt="" />
    </div>
    <div class="cnt-public">
      <div class="title-public">
        <p>Solution</p>
        <h3>
          <span class="line-l"></span>
          <span class="center">解决方案</span>
          <span class="line-r"></span>
        </h3>
      </div>
      <div class="wp">
        <div
          class="solve-list"
          v-for="(item, index) in list"
          :key="index"
          @click="skip(item)"
        >
          <div class="text">{{ item.title }}</div>
          <div class="img-wp">
            <img :src="item.cover" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
export default {
  name: "",
  data() {
    return {
      list: [],
      page: 1,
      pageSize: 9,
    };
  },
  methods: {
    gain() {
      this.axios({
        method: "get",
        url: "/wzn/scheme/page",
        params: {
          pageNo: this.page,
          pageSize: this.pageSize,
        },
      }).then((res) => {
        console.log("解决方案", res.data);
        this.list = res.data.data.list.reverse();
      });
    },
    skip(item) {
      this.$router.push({
        path: "/Solve/info",
        query: { title: item.title, cnt: item.content, imgUrl: item.cover },
      });
    },
  },
  created() {
    this.gain();
  },
};
</script>
    
<style scoped>
.solve {
  min-height: 100vh;
}
.wp {
  width: 60%;
  margin: 0 auto;
  cursor: pointer;
}
.solve-list {
  padding: 24px 20px;
  box-sizing: border-box;
  /* border-top: 1px solid #ccc; */
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  background: #fff;
}
.solve-list:hover .img-wp img {
  transform: scale(1.1);
}
.solve-list:hover .text {
  opacity: 0.6;
}

.text {
  align-self: flex-end;
  font-size: 48px;
  transition: all 0.4s;
}
.img-wp {
  flex: 0 0 33.3%;
  height: 200px;
  overflow: hidden;
}
.img-wp img {
  width: 100%;
  height: 100%;
  transition: all 0.4s;
}
</style>